import { Context } from "@nuxt/types";

export default async function (ctx: Context) {

    if (!ctx.route.matched.length) {
        const match = /^\/([A-z0-9]{10,11})\/?$/.exec(ctx.route.fullPath);

        if (match) {
            const key = match[1];

            try {
                const data = await ctx.store.dispatch('RestRequest', {
                    url: '/short',
                    params: {
                        key
                    }
                });

                if (data?.link) {
                    ctx.redirect(301, data.link);
                }
            } catch {}

        }

    }
}